import React, { FC, HTMLAttributes, ReactNode } from 'react';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../constants/hooks';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  header: ReactNode | string;
  children: string;
  withLogo?: boolean;
}

const YellowHeader: FC<IProps> = ({ header, children, withLogo }) => {
  const prefix = usePathPrefix();

  return (
    <div className={styles.header}>
      {withLogo && (
        <img
          src={`${prefix}/logo-black.svg`}
          alt="IceRock"
          className={styles.logo}
        />
      )}

      {header && <h1>{header}</h1>}

      <div
        className={styles.subtitle}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </div>
  );
};

export { YellowHeader };
