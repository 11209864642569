import React, { CSSProperties, FC, ReactElement, useMemo } from 'react';
import styles from './styles.module.scss';
import { TLink } from '../../../i18n/TLink';
import { t } from '../../../i18n';
import { usePathPrefix } from '../../../constants/hooks';
import { IDirectionTagsQueryNode } from '../../../queries/directionTags/types';

interface IProps {
  node: IDirectionTagsQueryNode['node'];
  headDirection: CSSProperties['flexDirection'];
  from?: string;
  buttons?: ReactElement;
}

const Card: FC<IProps> = ({ headDirection, node, from, buttons }) => {
  const {
    frontmatter: { name, description, background, icon, url },
  } = node;
  const prefix = usePathPrefix();

  const image = useMemo(
    () => background && `${prefix}${background}`,
    [prefix, background]
  );

  const isRowDirection = headDirection?.includes('row');
  const detailColor = image ? '#FFDB72' : '#684CDC';

  return (
    <TLink to={url} params={{ from }}>
      <div
        className={styles.card}
        style={{
          backgroundImage: image && `url('${image}')`,
          backgroundColor: !image ? '#F5F5F5' : undefined,
          color: !image ? '#151515' : undefined,
        }}
      >
        <div
          className={styles.card__head}
          style={{
            flexDirection: headDirection,
          }}
        >
          <div
            className={styles.card__icon}
            style={{
              flex: !isRowDirection ? '0 0 40px' : undefined,
              margin: !isRowDirection ? '0' : undefined,
            }}
          >
            <img src={`${prefix}${icon}`} alt={description} />
          </div>

          <div className={styles.card__title}>
            {isRowDirection && <div className={styles.card__dash} />}
            <div className={styles.card__name}>{name}</div>
          </div>
        </div>

        <div className={styles.card__description}>{description}</div>

        <div className={styles.card__line} />

        <div className={styles.card__more} style={{ color: detailColor }}>
          {buttons || (
            <>
              <span>{t('directions.detail_information')}</span>

              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                  fill={detailColor}
                />
              </svg>
            </>
          )}
        </div>
      </div>
    </TLink>
  );
};

export { Card };
